<template>
  <div class="gs-footer">
    <div class="gs-footer_box">
      <div class="box_flex">
        <div class="txt-cen">
          <img src="http://file.40017.cn/zhuketong/workflow/gongsheng/footer-one.png" alt="">
          <div>恭胜汇订房</div>
        </div>
        <div class="txt-cen gs-footer_box_two">
          <img src="http://file.40017.cn/zhuketong/workflow/gongsheng/footer-two.png" alt="">
          <div>恭胜汇</div>
        </div>
        <div class="txt-cen">
          <img src="http://file.40017.cn/zhuketong/workflow/gongsheng/footer-three.png" alt="">
          <div>恭胜酒店集团加盟</div>
        </div>
      </div>
      <div class="gs-hotline">
        <div class="gs-hotline_text">全国服务热线</div>
        <div class="gs-hotline_iphone">4006-399-399</div>
        <div class="gs-hotline_time">服务时间：09：00-18：00</div>
      </div>
    </div>
    <div class="gs-footer_text flex-center">上海恭胜酒店管理有限公司&nbsp;版权所有◎&nbsp;2016-2026沪ICP备14034343&nbsp;&nbsp;&nbsp;公网安备&nbsp;31011502008648号</div>
  </div>
</template>

<script setup name="Footer">


</script>

<style scoped lang="scss">
.gs-footer {
  height: 19.25rem;
  padding: 3.875rem 12.6875rem;
  box-sizing: border-box; 
  &_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    &_two {
      margin: 0px 1.25rem;
    }
  }
  &_text {
    font-weight: 400;
    font-size: .875rem;
    line-height: 2.3125rem;
  }
}
.box_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gs-hotline {
  margin-left: 3.75rem;
  &_text {
    font-size: 1.0625rem;
  }
  &_iphone {
    font-family: Microsoft YaHei;
    margin-top: .625rem;
    font-size: 2.125rem;
    font-weight: 700;
  }
  &_time {
    font-size: .75rem;
  }
}
img {
  width: 6.6875rem;
  height: 6.6875rem;
  margin-bottom: .375rem;
}
.txt-cen {
  text-align: center;
  font-size: .75rem;
  line-height: 1rem;
}
</style>

