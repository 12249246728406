<template>
  <div class="brand-box">
    <div class="column" v-for="(column, index) in currentColumns" :key="index">
      <div class="column-title layout-box">{{ column.title }}</div>
      <div class="column-content">
        <div
          class="flex-center"
          v-for="(content, i) in column.contents"
          :key="i"
          :class="getBoxClass(column.title)"
          @mouseover="handleMouseOver(index, i)"
          @mouseleave="handleMouseLeave(index, i)"
          @click="handleClick(index, i)"
          :style="{cursor: useColoredImages ? 'pointer' : '' }"
        >
          <img
            :src="getImageSrc(index, i)"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  useColoredImages?: boolean;
}>();

const router = useRouter();

const columns = [
  { title: '简约精品', contents: ['http://file.40017.cn/zhuketong/workflow/gongsheng/icon-qingji.png', 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-mini.png'] },
  { title: '现代精品', contents: ['http://file.40017.cn/zhuketong/workflow/gongsheng/icon-yp.png', 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-yk.png'] },
  { title: '城市精选', contents: ['http://file.40017.cn/zhuketong/workflow/gongsheng/icon-km.png', 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-amHotel.png', 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-amApartment.png'] },
  { title: '经济快捷', contents: ['http://file.40017.cn/zhuketong/workflow/gongsheng/icon-99Optimization.png', 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-99Inn.png', 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-99New.png'] },
];

const columnsH = [
  { title: '简约精品', contents: [{ url: 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-qingjiH.png', path: '/brand-introduction/qingji' }, { url: 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-miniH.png', path: '/brand-introduction/qingjiMini' }] },
  { title: '现代精品', contents: [{ url: 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-ypH.png', path: '/brand-introduction/yunFei' }, { url: 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-ykH.png', path: '/brand-introduction/yunKuan' }] },
  { title: '城市精选', contents: [{ url: 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-kmH.png', path: '/brand-introduction/openVine' }, { url: 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-amHotelH.png', path: '/brand-introduction/aimoHotel' }, { url: 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-amApartmentH.png', path: '/brand-introduction/aimoApartment' }] },
  { title: '经济快捷', contents: [{ url: 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-99OptimizationH.png', path: '/brand-introduction/99YouxuanHotel' }, { url: 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-99InnH.png', path: '/brand-introduction/99HotelChain' }, { url: 'http://file.40017.cn/zhuketong/workflow/gongsheng/icon-99NewH.png', path: '/brand-introduction/99XinbiaoHotel' }] },
];

const hoveredIndex = ref<{ columnIndex: number | null; contentIndex: number | null }>({ columnIndex: null, contentIndex: null });

const currentColumns = computed(() => (props.useColoredImages ? columnsH : columns));

const getBoxClass = (title: string) => {
  return title === '城市精选' || title === '经济快捷' ? 'layout-box2' : 'layout-box';
};

const handleMouseOver = (columnIndex: number, contentIndex: number) => {
  hoveredIndex.value = { columnIndex, contentIndex };
};

const handleMouseLeave = () => {
  hoveredIndex.value = { columnIndex: null, contentIndex: null };
};

const handleClick = (columnIndex: number, contentIndex: number) => {
  if (props.useColoredImages && columnsH[columnIndex] && columnsH[columnIndex].contents[contentIndex]) {
    const path = columnsH[columnIndex].contents[contentIndex].path;
    router.push(path);
  }
};

const getImageSrc = (columnIndex: number, contentIndex: number) => {
  if (props.useColoredImages && hoveredIndex.value.columnIndex === columnIndex && hoveredIndex.value.contentIndex === contentIndex) {
    return columns[columnIndex].contents[contentIndex];
  }
  return props.useColoredImages ? columnsH[columnIndex].contents[contentIndex].url : columns[columnIndex].contents[contentIndex];
};
</script>

<style scoped lang="scss">
.brand-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: .1875rem;
  box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.15);
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-title {
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  font-size: 1.125rem;
  color: #323232;
  margin-bottom: .1875rem;
}

.layout-box,
.layout-box2 {
  background: #fff;
  width: 10.1875rem;
  text-align: center;
}

.layout-box {
  height: 5.25rem;
  line-height: 5.125rem;
}

.layout-box2 {
  height: 3.4375rem;
  line-height: 3.4375rem;
}

.column-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .1875rem;
}
</style>
