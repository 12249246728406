

<template>
  <div class="screen-slider">
    <Header :isBlurred="isHeaderBlurred" />
    <div class="screen firstScreen" :style="{ transform: `translateY(${firstScreenTransform})`, opacity: firstScreenOpacity }">
      <slot name="first-screen"></slot>
    </div>
    <div class="screen secondScreen" :style="{ transform: `translateY(${secondScreenTransform})` }">
      <slot name="second-screen"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import WOW from 'wow.js';

const currentScreenIndex = ref(0);
const firstScreenTransform = ref('0px');
const secondScreenTransform = ref('100vh');
const firstScreenOpacity = ref(1);
const isHeaderBlurred = ref(false);

const handleScroll = (event) => {
  const secondScreenElement = document.querySelector('.secondScreen');

  if (currentScreenIndex.value === 0 && event.deltaY > 0) {
    event.preventDefault();
    currentScreenIndex.value = 1;
    firstScreenTransform.value = '-100vh';
    secondScreenTransform.value = '0px';
    firstScreenOpacity.value = 0;
    isHeaderBlurred.value = true;

    new WOW().init();
  } else if (currentScreenIndex.value === 1 && event.deltaY < 0) {
    if (secondScreenElement.scrollTop === 0) {
      event.preventDefault();
      currentScreenIndex.value = 0;
      firstScreenTransform.value = '0px';
      secondScreenTransform.value = '100vh';
      firstScreenOpacity.value = 1;
      isHeaderBlurred.value = false;
    }
  }
};

onMounted(() => {
  window.addEventListener('wheel', handleScroll, { passive: false });

  new WOW().init();
});

onUnmounted(() => {
  window.removeEventListener('wheel', handleScroll);
});
</script>

<style scoped>
.screen-slider {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.screen {
  height: 100vh;
  width: 100%;
  position: absolute;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.secondScreen {
  overflow-y: auto;
}
</style>
  