<template>
  <div :class="['gs-header', { blurred: isBlurred, blackText: isOnHotelBookingPage }]">
    <div class="gs-header_top flex-center" :style="{ borderBottom: isOnHotelBookingPage ? '1px solid #E3E3E3' : '1px solid hsla(0, 0%, 100%, .1)' }">
      <img :src="isOnHotelBookingPage ? 'http://file.40017.cn/zhuketong/workflow/gongsheng/logo.png' : 'http://file.40017.cn/zhuketong/workflow/gongsheng/logo-white.png'" alt="logo">
    </div>
    <div class="gs-header_switching flex-center">
      <nav>
        <ul class="nav-list">
          <li><router-link to="/">公司简介</router-link></li>
          <li @mouseenter="showPopup = true" @mouseleave="showPopup = false" class="nav-item">
            <router-link to="/brand-introduction">品牌介绍</router-link>
            <div v-if="showPopup" class="brand-popup">
              <div class="triangle"></div>
              <!-- <div style="height: 1rem;background-color: transparent;"></div> -->
              <div class="brand-box_title">
                <img style="width: 10.0625rem;height: 3.1875rem;" src="http://file.40017.cn/zhuketong/workflow/gongsheng/logo.png" alt="">
              </div>
              <div class="brand-box">
                <Brand :useColoredImages="true"/>
              </div>
            </div>
          </li>
          <li><router-link to="/book-hotels">酒店预订</router-link></li>
          <li><router-link to="/about-joining">关于加盟</router-link></li>
          <li><router-link to="/contact-gongsheng">联系恭胜</router-link></li>
        </ul>
      </nav>
    </div>
  </div>
</template>


<script setup>
defineProps({
  isBlurred: Boolean
});
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
const isOnHotelBookingPage = ref(false);
const route = useRoute();
const showPopup = ref(false);
import Brand from '../components/brand.vue';
// 监听路由变化
watch(
  () => route.path,
  (newPath) => {
    isOnHotelBookingPage.value = newPath === '/book-hotels' ||  newPath === '/brand-introduction/aimoHotel' || newPath === '/brand-introduction/aimoApartment' ;
  },
  { immediate: true } // 初始化时立即检查路由
);
</script>

<style scoped lang="scss">
.gs-header {
  z-index: 999;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: backdrop-filter 0.3s ease, background-color 0.3s ease;

  &_top {
    width: 100%;
    height: 3.4375rem;
    border-bottom: 1px solid hsla(0, 0%, 100%, .1);
    img {
      width: 5.75rem;
      height: 1.875rem;
    }
  }
  &_switching {
    height: 1.875rem;
    nav {
      .nav-list {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          width: 6.4375rem;
          text-align: center;
          font-size: .75rem;
          position: relative;
          a {
            text-decoration: none;
            color: #fff;
          }
          .router-link-active {
            &::after {
              content: '';
              display: block;
              width: 1.5625rem;
              height: .0625rem;
              background-color: #aa1f36;
              position: absolute;
              bottom: -5px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }

  &.blurred {
    backdrop-filter: blur(20px);
    background-color: rgba(0, 0, 0, .15);
    opacity: 1;
  }
  &.blackText {
    .nav-list a {
      color: #323232;
    }
  }
}



.brand-popup {
  position: absolute;
  top: 2.125rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  background-color: rgba(20, 20, 20, 0.35);
  border-radius: 1rem;

  .triangle {
    position: absolute;
    top: -0.875rem;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 1rem solid #fff;
  }

  .brand-box {
    display: flex;
    justify-content: space-between;
    border-radius: 0 0 1rem 1rem;
    overflow: hidden;
    &_title {
      background-color: #fff;
      border-radius:1rem 1rem 0 0;
      height: 5.3125rem;
      display: flex;
      justify-content: center;
      margin-bottom: .1875rem;
      align-items: center;
    }
  }
}
</style>